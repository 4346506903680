<template>
  <v-card class="ma-6 px-4 pt-5">
    <v-row align="center" justify="center" class="text-center">
      <v-col cols="12"><h3>table</h3></v-col>
      
      <v-col cols="12" class="mt-6">
        <v-row>
          <v-col cols="2" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mt-4"
            color="#833133"
            style="color: white"
            @click="createForm()"
            ><v-icon left dark> mdi-plus-circle </v-icon>Export
          </v-btn>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :items-per-page="10"
          class="elevation-1"
        >
          <!-- <template v-slot:[`item.idCardImg`]="{ item }">
          <img @click="popup(item)" :src="item.idCardImg" />
        </template> -->

          <!-- <template v-slot:[`item.activeFlag`]="{ item }">
          <v-row justify="center">
            <v-btn v-if="item.activeFlag === true" disabled>อนุมัติแล้ว</v-btn>
            <v-btn @click="approve(item)" v-else>รออนุมัติ</v-btn>
          </v-row>
        </template> -->
          <template v-slot:[`item.action`]="{ item }">
            <v-row justify="center">
              <v-col cols="4"
                ><v-btn @click="DeleteForm(item)" color="white"
                  ><v-icon>mdi-delete</v-icon></v-btn
                ></v-col
              >
              <v-col cols="4"
                ><v-btn @click="UpdateForm(item)" color="#11cdef" dark
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></v-col
              >
              <v-col cols="4"
                ><v-btn @click="GetQuestions(item)" color="#833133" dark
                  ><v-icon>mdi-magnify</v-icon></v-btn
                ></v-col
              >
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      //   dialog: false,
      headers: [
        { text: "ลำดับที่", value: "count", align: "center" },
        { text: "สถานีตำรวจ", value: "name", align: "center" },
        { text: "จังหวัด", value: "province", align: "center" },
        {
          text: "จำนวนการตอบแบบสอบถามทั้งหมด",
          value: "countSurvey",
          align: "center",
        },
        {
          text: "แบบสอบถามภายนอกสถานีตำรวจ",
          value: "outSurvey",
          align: "center",
        },
        { text: "แจ้งความ ร้องทุกข์", value: "action", align: "center" },
        {
          text: "แจ้งความเอกสารหาย หรือแจ้งความเป็นหลักฐาน",
          value: "lost",
          align: "center",
        },
        { text: "จ่ายค่าปรับจราจร", value: "traffic", align: "center" },
        {
          text: "ขออนุญาตต่างๆ/ งานต่างด้าว/ พิมพ์มือตรวจสอบประวัติ",
          value: "alien",
          align: "center",
        },
        {
          text: "ขอความช่วยเหลือ/ ติดตามคดี/ ปรึกษา",
          value: "help",
          align: "center",
        },
      ],
      list: [],
      
      count: 0,
      
    };
  },
};
</script>