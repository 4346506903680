<template>
  <div style="background-color: #0000">
    <v-card class="ma-6 pb-6">
      <loading :active.sync="loading"></loading>
      <v-row align="center" justify="center" class="text-center" no-gutters>
        <h2 class="pt-5 headtitle">สรุปสถิติคะแนนแบบสอบถาม</h2>
      </v-row>
      <v-row align="center" justify="center" class="text-center" no-gutters>
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-col cols="7">
            <v-autocomplete
              outlined
              label="ชุดแบบประเมิน"
              dense
              v-model="questionSummary"
              :items="questionSummaryItems"
              item-text="text"
              item-value="value"
              @change="changeDropDown()"
            ></v-autocomplete
          ></v-col>
          <v-col
            cols="7"
            v-if="
              questionSummary == 'customForm' ||
              questionSummary == 'surveyPhase2' ||
              questionSummary == 'surveyPhase2Officer' ||
              questionSummary == 'surveyPhase1'
            "
          >
            <!-- ////////////////// -->
            <v-row v-if="opendiv199" no-gutters>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกพื้นที่"
                  v-model="data.area"
                  :items="items2"
                  item-text="text"
                  item-value="group"
                  @change="onChangeArea()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกจังหวัด"
                  v-model="data.province"
                  :items="items3"
                  item-text="province"
                  item-value="province"
                  @change="onChangeProvince()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกสถานี"
                  v-model="data.station"
                  :items="items4"
                  item-text="station"
                  item-value="station"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="opendiv2" no-gutters>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกพื้นที่"
                  v-model="data.area"
                  :items="items2"
                  item-text="text"
                  item-value="group"
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกจังหวัด"
                  v-model="data.province"
                  :items="items3"
                  item-text="province"
                  item-value="province"
                  @change="onChangeProvince()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกสถานี"
                  v-model="data.station"
                  :items="items4"
                  item-text="station"
                  item-value="station"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="opendiv3" no-gutters>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกพื้นที่"
                  v-model="data.area"
                  :items="items2"
                  item-text="text"
                  item-value="group"
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกจังหวัด"
                  v-model="data.province"
                  :items="items3"
                  item-text="province"
                  item-value="province"
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกสถานี"
                  v-model="data.station"
                  :items="items4"
                  item-text="station"
                  item-value="station"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="opendiv4" no-gutters>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกพื้นที่"
                  v-model="data.area"
                  :items="items2"
                  item-text="text"
                  item-value="group"
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกจังหวัด"
                  v-model="data.province"
                  :items="items3"
                  item-text="province"
                  item-value="province"
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  placeholder="เลือกสถานี"
                  v-model="data.station"
                  :items="items4"
                  item-text="station"
                  item-value="station"
                  disabled
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="7"
            v-if="
              questionSummary == 'questionSummary1' ||
              questionSummary == 'questionSummary2'
            "
          >
            <v-autocomplete
              outlined
              label="สังกัด"
              dense
              v-model="clan"
              :items="items"
              item-text="searchText"
              item-value="station"
              placeholder="สังกัด"
            ></v-autocomplete
          ></v-col>

          <v-col cols="7">
            <v-menu
              ref="menufrom"
              v-model="menufrom"
              :close-on-content-click="false"
              :return-value.sync="datefrom"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datefrom"
                  label="วันที่เริ่ม"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker v-model="datefrom" no-title scrollable>
                <v-btn text color="primary" @click="menufrom = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menufrom.save(datefrom)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="7">
            <v-menu
              ref="menuto"
              v-model="menuto"
              :close-on-content-click="false"
              :return-value.sync="dateto"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateto"
                  label="วันที่สิ้นสุด"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateto" no-title scrollable>
                <v-btn text color="primary" @click="menuto = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menuto.save(dateto)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-row>
      <v-row align="center" justify="center" class="text-center" no-gutters>
        <v-btn @click="clear()" class="mr-4">เคลียร์</v-btn>

        <v-btn @click="getReport()" color="#833133" dark>ค้นหา</v-btn>
      </v-row>
      <div v-if="questionSummary == 'questionSummary1' && isShow">
        <v-divider
          style="margin-left: 20px; margin-right: 20px; margin-top: 20px"
        ></v-divider>
        <v-row
          align="center"
          justify="center"
          class="text-center"
          style="margin-top: 30px"
        >
          <v-col cols="12">
            <b>{{ questionSummary1.name }}</b
            ><br />
            <b>{{ questionSummary1.description }}</b>
            <br />
            <b>{{ sangkat }}</b>
          </v-col>
          <v-col cols="12">
            <ExportPeople
              :itemProduct="exportRawPeople"
              :formDate="datefrom"
              :toDate="dateto"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <div id="chart" class="text-center">
              <apexchart
                type="radialBar"
                height="350"
                :options="chartOptions"
                :series="[valueToPercent(series)]"
              ></apexchart>
            </div>
          </v-col>
          <v-divider
            style="margin-top: 20px; margin-bottom: 20px"
            vertical
          ></v-divider>
          <v-col cols="12" sm="6" class="text-left px-6">
            <!-- ผู้ร่วมทำแบบสอบถามทั้งหมด:
          {{ questionSummary1.participants.all }}
          คน -->
            <!-- <br /> -->
            ผู้ที่ทำแบบสอบถามทั้งหมด:
            {{ questionSummary1.participants.done }}
            คน
            <br />
            ผู้ที่เปิดแล้วไม่ได้ทำแบบสอบถาม:
            {{ questionSummary1.participants.not }}
            คน
            <br />
            <!-- ผู้ที่ทำแบบสอบถามแบบให้คะแนนทั้งหมด
          {{ questionSummary1.participants.choice }} คน
          <br /> -->
            ผู้แสดงความคิดเห็นทั้งหมด {{ list.length }} คน
          </v-col>
        </v-row>

        <v-row align="center" justify="center" class="text-center">
          <v-btn v-if="!showdata" @click="showItem()">ดูรายละเอียด</v-btn>
          <v-btn v-else @click="showItem()">ซ่อน</v-btn>

          <v-btn v-if="!showdataTable" class="ml-4" @click="showTable()"
            >ดูข้อเสนอแนะ</v-btn
          >
          <v-btn v-else class="ml-4" @click="showTable()">ซ่อน</v-btn>
        </v-row>
        <v-form v-if="showdata">
          <v-row
            align="center"
            justify="center"
            class="text-center mt-4"
            v-for="(n, index) in questionSummary1.summary"
            :key="index"
          >
            <v-col cols="9" v-if="index == 0" class="text-left"
              ><b>1. หัวข้อหลักสูตรการฝึกอบรม</b></v-col
            >
            <v-col cols="9" v-if="index == 4" class="text-left"
              ><b>2. วิทยากร</b></v-col
            >
            <v-col cols="9" v-if="index == 8" class="text-left"
              ><b>3. ความเหมาะสม/สิ่งอำนวยความสะดวก</b></v-col
            >
            <v-col cols="9" class="mt-0 text-left">
              {{ n.question }}
              <k-progress
                :percent="n.totalAverage.toFixed(1) * 20"
                :format="format"
              ></k-progress>
            </v-col>
          </v-row>
        </v-form>
        <v-form v-if="showdataTable">
          <v-col cols="12" class="mt-4">
            <v-data-table
              :headers="headers"
              :items="list"
              :items-per-page="10"
              class="elevation-2"
            >
            </v-data-table>
          </v-col>
        </v-form>
      </div>
      <div v-if="questionSummary == 'questionSummary2' && isShow">
        <v-divider
          style="margin-left: 20px; margin-right: 20px; margin-top: 20px"
        ></v-divider>
        <v-row
          align="center"
          justify="center"
          class="text-center"
          style="margin-top: 10px"
        >
          <v-col cols="12">
            <b>{{ questionSummary2.name }}</b
            ><br />
            <b>{{ questionSummary2.description }}</b>
            <br />
            <b>{{ sangkat }}</b>
          </v-col>
          <v-col cols="12">
            <ExportStaff
              :itemProduct="exportRawStaff"
              :formDate="datefrom"
              :toDate="dateto"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <div id="chart">
              <apexchart
                type="radialBar"
                height="350"
                :options="chartOptions"
                :series="[valueToPercent(series2)]"
              ></apexchart>
            </div>
          </v-col>
          <v-divider
            style="margin-top: 20px; margin-bottom: 20px"
            vertical
          ></v-divider>
          <v-col cols="12" sm="6" class="text-left px-6">
            <!-- ผู้ร่วมทำแบบสอบถามทั้งหมด:
          {{ questionSummary2.participants.all }}
          คน
          <br /> -->
            ผู้ที่ทำแบบสอบถามทั้งหมด:
            {{ questionSummary2.participants.done }}
            คน
            <br />
            ผู้ที่เปิดแล้วไม่ได้ทำแบบสอบถาม:
            {{ questionSummary2.participants.not }}
            คน
            <br />
            <!-- ผู้ที่ทำแบบสอบถามแบบให้คะแนนทั้งหมด
          {{ questionSummary2.participants.choice }} คน
          <br /> -->
            ผู้แสดงความคิดเห็นทั้งหมด
            {{ list2.length }} คน
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="text-center">
          <v-btn v-if="!showdata2" @click="showItem2()">ดูรายละเอียด</v-btn>
          <v-btn v-else @click="showItem2()">ซ่อน</v-btn>
          <v-btn v-if="!showdataTable2" class="ml-4" @click="showTable2()"
            >ดูข้อเสนอแนะ</v-btn
          >
          <v-btn v-else class="ml-4" @click="showTable2()">ซ่อน</v-btn>
        </v-row>
        <v-form v-if="showdata2">
          <v-row align="center" justify="center" class="text-center">
            <v-col
              cols="9"
              class="mt-4 text-left"
              v-for="(n, index) in questionSummary2.summary"
              :key="index"
            >
              {{ n.question }}
              <k-progress
                :percent="n.totalAverage.toFixed(1) * 20"
                :format="format"
              ></k-progress>
            </v-col>
          </v-row>
        </v-form>
        <v-form v-if="showdataTable2">
          <v-col cols="12" class="mt-4">
            <v-data-table
              :headers="headers"
              :items="list2"
              :items-per-page="10"
              class="elevation-2"
            >
            </v-data-table></v-col
        ></v-form>
      </div>
      <!-- <div v-if="checkCircle == '' && isShow">
        <v-divider
          style="margin-left: 20px; margin-right: 20px; margin-top: 20px"
        ></v-divider>
        <v-row
          align="center"
          justify="center"
          class="text-center"
          style="margin-top: 30px"
        >
          <v-col cols="12">
            <b>{{ questionSummary1.name }}</b
            ><br />
            <b>{{ questionSummary1.description }}</b>
            <br />
            <b>{{ sangkat }}</b>
          </v-col>
          <v-col cols="12" sm="6">
            <div id="chart" class="text-center">
              <apexchart
                type="radialBar"
                height="350"
                :options="chartOptions"
                :series="[valueToPercent(series)]"
              ></apexchart>
            </div>
          </v-col>
          <v-divider
            style="margin-top: 20px; margin-bottom: 20px"
            vertical
          ></v-divider>
          <v-col cols="12" sm="6" class="text-left px-6">
            จำนวนผู้เข้าร่วมประเมินความพึงพอใจ:
            {{
              questionSummary1.participants.done +
                questionSummary1.participants.not
            }}
            คน
            <br />
            Scan QRCODE แล้วประเมินความพึงพอใจ:
            {{ questionSummary1.participants.done }}
            คน
            <br />
            Scan QRCODE แล้วไม่ประเมินความพึงพอใจ:
            {{ questionSummary1.participants.not }}
            คน
            <br />
            ผู้แสดงความคิดเห็นทั้งหมด {{ list.length }} คน
          </v-col>
        </v-row>

        <v-row align="center" justify="center" class="text-center">
          <v-btn v-if="!showdata" @click="showItem()">ดูรายละเอียด</v-btn>
          <v-btn v-else @click="showItem()">ซ่อน</v-btn>

          <v-btn v-if="!showdataTable" class="ml-4" @click="showTable()"
            >ดูข้อเสนอแนะ</v-btn
          >
          <v-btn v-else class="ml-4" @click="showTable()">ซ่อน</v-btn>
        </v-row>
        <v-row align="center" justify="center" class="text-center">
          <v-btn class="mt-4"
            ><download-excel
              class="btn btn-default"
              :data="questionSummary1.participantsQuestion"
              :fields="participantsQuestion"
              worksheet="My Worksheet"
              name="Question_points.xls"
              :header="
                questionSummary1.name +
                  questionSummary1.description +
                  ' ' +
                  sangkat
              "
              ><img src="@/assets/Excel-Logo.png" width="50px" />
              ดาวน์โหลดคะแนน
            </download-excel></v-btn
          >

          <v-btn class="ml-4 mt-4"
            ><download-excel
              class="btn btn-default"
              :data="list"
              :fields="json_fields"
              worksheet="My Worksheet"
              name="Comment.xls"
              :header="
                questionSummary1.name +
                  questionSummary1.description +
                  ' ' +
                  sangkat
              "
              ><img src="@/assets/Excel-Logo.png" width="50px" />
              ดาวน์โหลดข้อเสนอแนะ
            </download-excel></v-btn
          >
        </v-row>

        <v-form v-if="showdata">
          <v-row
            align="center"
            justify="center"
            class="text-center mt-4"
            v-for="(n, index) in questionSummary1.summary"
            :key="index"
          >
            <v-col cols="9" v-if="index == 0" class="text-left"
              ><b>1. หัวข้อหลักสูตรการฝึกอบรม</b></v-col
            >
            <v-col cols="9" v-if="index == 4" class="text-left"
              ><b>2. วิทยากร</b></v-col
            >
            <v-col cols="9" v-if="index == 8" class="text-left"
              ><b>3. ความเหมาะสม/สิ่งอำนวยความสะดวก</b></v-col
            >
            <v-col cols="9" class="mt-0 text-left">
              {{ n.question }}
              <k-progress
                :percent="n.totalAverage.toFixed(1) * 20"
                :format="format"
              ></k-progress>
            </v-col>
          </v-row>
        </v-form>
        <v-form v-if="showdataTable">
          <v-col cols="12" class="mt-4">
            <v-data-table
              :headers="headers"
              :items="list"
              :items-per-page="10"
              class="elevation-2"
            >
            </v-data-table>
          </v-col>
        </v-form>
        <v-divider
          style="margin-left: 20px; margin-right: 20px; margin-top: 20px"
        ></v-divider>
        <v-row
          align="center"
          justify="center"
          class="text-center"
          style="margin-top: 10px"
        >
          <v-col cols="12">
            <b>{{ questionSummary2.name }}</b
            ><br />
            <b>{{ questionSummary2.description }}</b>
            <br />
            <b>{{ sangkat }}</b>
          </v-col>
          <v-col cols="12" sm="6">
            <div id="chart">
              <apexchart
                type="radialBar"
                height="350"
                :options="chartOptions"
                :series="[valueToPercent(series2)]"
              ></apexchart>
            </div>
          </v-col>
          <v-divider
            style="margin-top: 20px; margin-bottom: 20px"
            vertical
          ></v-divider>
          <v-col cols="12" sm="6" class="text-left px-6">
            จำนวนผู้เข้าร่วมประเมินความพึงพอใจ:
            {{
              questionSummary2.participants.done +
                questionSummary2.participants.not
            }}
            คน
            <br />
            Scan QRCODE แล้วประเมินความพึงพอใจ:
            {{ questionSummary2.participants.done }}
            คน
            <br />
            Scan QRCODE แล้วไม่ประเมินความพึงพอใจ:
            {{ questionSummary2.participants.not }}
            คน
            <br />
            จำนวนความคิดเห็นทั้งหมด
            {{ list2.length }} คน
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="text-center">
          <v-btn v-if="!showdata2" @click="showItem2()">ดูรายละเอียด</v-btn>
          <v-btn v-else @click="showItem2()">ซ่อน</v-btn>
          <v-btn v-if="!showdataTable2" class="ml-4" @click="showTable2()"
            >ดูข้อเสนอแนะ</v-btn
          >
          <v-btn v-else class="ml-4" @click="showTable2()">ซ่อน</v-btn>
        </v-row>
        <v-row align="center" justify="center" class="text-center">
          <v-btn class="mt-4"
            ><download-excel
              class="btn btn-default"
              :data="questionSummary2.participantsQuestion"
              :fields="participantsQuestion"
              worksheet="My Worksheet"
              name="Question_points.xls"
              :header="
                questionSummary2.name +
                  questionSummary2.description +
                  ' ' +
                  sangkat
              "
              ><img src="@/assets/Excel-Logo.png" width="50px" />
              ดาวน์โหลดคะแนน
            </download-excel></v-btn
          >

          <v-btn class="ml-4 mt-4"
            ><download-excel
              class="btn btn-default"
              :data="list2"
              :fields="json_fields"
              worksheet="My Worksheet"
              name="Comment.xls"
              :header="
                questionSummary2.name +
                  questionSummary2.description +
                  ' ' +
                  sangkat
              "
              ><img src="@/assets/Excel-Logo.png" width="50px" />
              ดาวน์โหลดข้อเสนอแนะ
            </download-excel></v-btn
          >
        </v-row>
        <v-form v-if="showdata2">
          <v-row align="center" justify="center" class="text-center">
            <v-col
              cols="9"
              class="mt-4 text-left"
              v-for="(n, index) in questionSummary2.summary"
              :key="index"
            >
              {{ n.question }}
              <k-progress
                :percent="n.totalAverage.toFixed(1) * 20"
                :format="format"
              ></k-progress>
            </v-col>
          </v-row>
        </v-form>
        <v-form v-if="showdataTable2">
          <v-col cols="12" class="mt-4">
            <v-data-table
              :headers="headers"
              :items="list2"
              :items-per-page="10"
              class="elevation-2"
            >
            </v-data-table></v-col
        ></v-form>
      </div> -->
      <div v-if="questionSummary == 'customForm' && isShow">
        <v-row align="center" justify="center" class="ml-2">
          <v-col cols="12" md="12" sm="12" class="mt-4">
            <b>สรุปสถิติคะแนนแบบสอบถาม แบบประเมินสภาพชุมชน</b>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <span><b>เพศ</b></span>
            <!-- <div id="chart"> -->
            <apexchart
              id="chart"
              type="pie"
              width="350"
              :options="chartOptionsSex"
              :series="seriesSex"
            ></apexchart>
            <!-- </div> -->
          </v-col>
          <v-col cols="12" md="6" sm="12" class="mt-4">
            <span><b>ช่วงอายุ</b></span>
            <!-- <div id="chart"> -->
            <apexchart
              id="chart"
              type="pie"
              width="350"
              :options="chartOptionsAge"
              :series="seriesAge"
            ></apexchart>
            <!-- </div> -->
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span>ระดับการศึกษา </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ1"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span>ลักษณะที่พักอาศัยปัจจุบัน </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ2"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span>อาศัยอยู่ในชุมชน/หมู่บ้านนี้มาเป็นเวลาเท่าใด </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ3"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span>ปัจจุบันท่านประกอบอาชีพอะไร </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ4"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span>ท่านอาศัยอยู่ในชุมชน/หมูบ้านนี้มีความสุขมากน้อยเพียงใด </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ5"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีการขายยาเสพติดหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ6"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้เสพยาเสพติดหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ7"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีปัญหาที่เกี่ยวข้องกับยาเสพติด เช่น ผู้สมคบ ผู้สนับสนุน
              ผู้ให้ความช่วยเหลือหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ8"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้ครอบครอง
              อาวุธปืนเถือน/วัตถุระเบิด/อาวุธสงครามหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ9"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้มีพฤติกรรมชอบเล่นการพนัน/ขายหวยออนใต้ดินหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ10"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมลักลอบตัดไม้หวงห้าม/ไม่มีค่าหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ11"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมบุกรุกที่สาธารณะ/ทำลายทรัพยากรธรรมชาติหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ12"
              ></apexchart>
            </div>
          </v-col>
          <!-- <v-col cols="12" md="12" sm="12">
          <v-divider></v-divider><br />
          <span
            >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้ที่มีพฤติกรรมลักลอบจำหน่ายสินค้าหนีภาษีหรือไม่
          </span>
          <div id="chart">
            <apexchart
              type="bar"
              height="350"
              :options="chartOptionsQ1"
              :series="seriesQ13"
            ></apexchart>
          </div>
        </v-col> -->
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมออกเงินกู้นอกระบบหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ13"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมเป็นผู้มีอิทธิพลหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ14"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมการขับรถแว้น คึกคะนอง เสียงดัง
              หรือก่อความรำคานหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ15"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีที่มีพฤติกรรมลักเล็กขโมยน้อยหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ16"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่มีที่มีพฤติกรรมจับกลุ่มดื่มสุรามั่วสุม/ชอบนำพวกทะเลาะวิวาทหรือไม่
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ17"
              ></apexchart>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-divider></v-divider><br />
            <span
              >ท่านคิดว่าสถานการณ์การแผ่ระบาดยาเสพติดในชุมชนท่านอยู่ระดับใด
            </span>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsQ1"
                :series="seriesQ18"
              ></apexchart>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="questionSummary == 'surveyPhase2' && isShow">
        <br />

        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="12" sm="12">
            <span style="font-size: 8px"
              ><b
                ><h3 class="text-center py-6 headtitle">
                  สรุปคะแนนการประเมินความพึงพอใจ (สำหรับประชาชนในพื้นที่)
                </h3></b
              >
            </span>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="text-center"
          style="margin-top: 10px"
        >
          <v-col cols="12">
            <ExportComment
              :list="reportComment"
              :formDate="datefrom"
              :toDate="dateto"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <div id="chart">
              <apexchart
                type="radialBar"
                height="350"
                :options="chartOptions"
                :series="[valueToPercent(avgReport)]"
              ></apexchart>
            </div>
          </v-col>
          <v-divider
            style="margin-top: 20px; margin-bottom: 20px"
            vertical
          ></v-divider>
          <v-col cols="12" sm="6" class="text-left px-6">
            <!-- ผู้ร่วมทำแบบสอบถามทั้งหมด:
          {{ questionSummary2.participants.all }}
          คน
          <br /> -->
            ผู้ที่ทำแบบสอบถามทั้งหมด:
            {{ report[0].countAns }}
            คน
            <br />
            <!-- ผู้ที่ทำแบบสอบถามแบบให้คะแนนทั้งหมด
          {{ questionSummary2.participants.choice }} คน
          <br /> -->
            ผู้แสดงความคิดเห็นทั้งหมด
            {{ reportComment.length }} คน
          </v-col>
        </v-row>
        <v-card class="elevation-1 pa-4">
          <v-row
            v-for="(i, index) in report"
            :key="index"
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" md="12" sm="12">
              <span style="font-size: 8px"
                ><b>{{ i.topic }}</b>
              </span>
              <br />
              <br />
              <span style="font-size: 12px !important"
                >ผู้ตอบจำนวน
                <span
                  style="
                    color: #833133;
                    font-size: 12px !important;
                    font-weight: 800;
                  "
                  >{{ i.countAns }} </span
                >คน<br
              /></span>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <k-progress
                :percent="i.ansAvg.toFixed(2) * 20"
                :format="format"
              ></k-progress>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <br />
            </v-col>
          </v-row>
          <!-- <v-row>
          <v-divider></v-divider><br />
          <v-col cols="12"><h3>สรุปยอดจำนวนผู้ตอบแบบสอบถาม</h3></v-col>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="list1"
              :search="search"
              :items-per-page="10"
              class="elevation-1"
              hide-default-footer
              disable-pagination
            >
            </v-data-table>
          </v-col>
        </v-row> -->
        </v-card>
      </div>
      <div v-if="questionSummary == 'surveyPhase2Officer' && isShow">
        <br />

        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="12" sm="12">
            <span style="font-size: 8px"
              ><b
                ><h3
                  class="text-center py-6 headtitle"
                  style="font-weight: 800"
                >
                  สรุปคะแนนการประเมินความพึงพอใจ
                  (สำหรับชุดปฏิบัติการที่ลงปัฏิบัติในพื้นที่)
                </h3></b
              >
            </span>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="text-center"
          style="margin-top: 10px"
        >
          <v-col cols="12">
            <ExportCommentOfficer
              :list="reportOfficerComment"
              :formDate="datefrom"
              :toDate="dateto"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <div id="chart">
              <apexchart
                type="radialBar"
                height="350"
                :options="chartOptions"
                :series="[valueToPercent(avgReportOfficer)]"
              ></apexchart>
            </div>
          </v-col>
          <v-divider
            style="margin-top: 20px; margin-bottom: 20px"
            vertical
          ></v-divider>
          <v-col cols="12" sm="6" class="text-left px-6">
            <!-- ผู้ร่วมทำแบบสอบถามทั้งหมด:
          {{ questionSummary2.participants.all }}
          คน
          <br /> -->
            ผู้ที่ทำแบบสอบถามทั้งหมด:
            {{ reportOfficer[0].countAns }}
            คน
            <br />
            <!-- ผู้ที่ทำแบบสอบถามแบบให้คะแนนทั้งหมด
          {{ questionSummary2.participants.choice }} คน
          <br /> -->
            ผู้แสดงความคิดเห็นทั้งหมด
            {{ reportOfficerComment.length }} คน
          </v-col>
        </v-row>
        <v-card class="elevation-1 pa-4">
          <v-row
            v-for="(i, index) in reportOfficer"
            :key="index"
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" md="12" sm="12">
              <span style="font-size: 8px"
                ><b>{{ i.topic }}</b>
              </span>
              <br />
              <br />
              <span style="font-size: 12px !important"
                >ผู้ตอบจำนวน
                <span
                  style="
                    color: #833133;
                    font-size: 12px !important;
                    font-weight: 800;
                  "
                  >{{ i.countAns }} </span
                >คน<br
              /></span>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <k-progress
                :percent="i.ansAvg.toFixed(2) * 20"
                :format="format"
              ></k-progress>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <br />
            </v-col>
          </v-row>
          <!-- <v-row>
          <v-divider></v-divider><br />
          <v-col cols="12"><h3>สรุปยอดจำนวนผู้ตอบแบบสอบถาม</h3></v-col>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="list1"
              :search="search"
              :items-per-page="10"
              class="elevation-1"
              hide-default-footer
              disable-pagination
            >
            </v-data-table>
          </v-col>
        </v-row> -->
        </v-card>
      </div>
      <div v-if="questionSummary == 'surveyPhase1' && isShow">
        <br />

        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="12" sm="12">
            <span style="font-size: 8px"
              ><b
                ><h3
                  class="text-center py-6 headtitle"
                  style="font-weight: 800"
                >
                  แบบประเมินสภาพชุมชน (สำหรับชุดปฏิบัติการที่ลงปฏิบัติในพื้นที่)
                </h3></b
              >
            </span>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="text-center"
          style="margin-top: 10px"
        >
          <v-col cols="12">
            <ExportCommentOfficer
              :list="reportCommentPhase1"
              :formDate="datefrom"
              :toDate="dateto"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <div id="chart">
              <apexchart
                type="radialBar"
                height="350"
                :options="chartOptions"
                :series="[valueToPercent(avgReportPhase1)]"
              ></apexchart>
            </div>
          </v-col>
          <v-divider
            style="margin-top: 20px; margin-bottom: 20px"
            vertical
          ></v-divider>
          <v-col cols="12" sm="6" class="text-left px-6">
            <!-- ผู้ร่วมทำแบบสอบถามทั้งหมด:
          {{ questionSummary2.participants.all }}
          คน
          <br /> -->
            ผู้ที่ทำแบบสอบถามทั้งหมด:
            {{ reportPhase1[0].countAns }}
            คน
            <br />
            <!-- ผู้ที่ทำแบบสอบถามแบบให้คะแนนทั้งหมด
          {{ questionSummary2.participants.choice }} คน
          <br /> -->
            ผู้แสดงความคิดเห็นทั้งหมด
            {{ reportCommentPhase1.length }} คน
          </v-col>
        </v-row>
        <v-card class="elevation-1 pa-4">
          <v-row
            v-for="(i, index) in reportPhase1"
            :key="index"
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" md="12" sm="12">
              <span style="font-size: 8px"
                ><b>{{ i.topic }}</b>
              </span>
              <br />
              <br />
              <span style="font-size: 12px !important"
                >ผู้ตอบจำนวน
                <span
                  style="
                    color: #833133;
                    font-size: 12px !important;
                    font-weight: 800;
                  "
                  >{{ i.countAns }} </span
                >คน<br
              /></span>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <k-progress
                :percent="i.ansAvg.toFixed(2) * 20"
                :format="format"
              ></k-progress>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <br />
            </v-col>
          </v-row>
          <!-- <v-row>
          <v-divider></v-divider><br />
          <v-col cols="12"><h3>สรุปยอดจำนวนผู้ตอบแบบสอบถาม</h3></v-col>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="list1"
              :search="search"
              :items-per-page="10"
              class="elevation-1"
              hide-default-footer
              disable-pagination
            >
            </v-data-table>
          </v-col>
        </v-row> -->
        </v-card>
      </div>
    </v-card>
    <!-- <PieChart /> -->
    <!-- <ReportTable /> -->
  </div>
</template>
<script>
import moment from "moment";
import ExportCustomForm from "@/views/ExportCustomForm";
import ExportComment from "@/views/ExportExcel/ExportComment";
import ExportCommentOfficer from "@/views/ExportExcel/ExportCommentOfficer";
import ExportCustomFormTable from "@/views/ExportCustomFormTable";
import ExportStaff from "@/views/ExportExcel/ExportStaff";
import ExportPeople from "@/views/ExportExcel/ExportPeople";
import PieChart from "@/components/report/pieChart";
import ReportTable from "@/components/report/ReportTable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
export default {
  components: {
    PieChart,
    ReportTable,
    Loading,
    ExportCustomForm,
    ExportCustomFormTable,
    ExportStaff,
    ExportPeople,
    ExportComment,
    ExportCommentOfficer,
  },
  data() {
    var station = [];
    var data = [];
    for (let i = 1; i < 30; i++) {
      station.push("สภ." + i);
      data.push(i * Math.floor(Math.random() * 10));
    }

    return {
      data: {
        area: "",
        province: "",
        station: "",
      },
      checklogin: "",
      opendiv199: false,
      opendiv2: false,
      opendiv3: false,
      opendiv4: false,
      items: [],
      items2: [
        { text: "ทั่วประเทศ", group: "" },
        { text: "นครบาล", group: "น." },
        { text: "ภ.1", group: "ภ.1" },
        { text: "ภ.2", group: "ภ.2" },
        { text: "ภ.3", group: "ภ.3" },
        { text: "ภ.4", group: "ภ.4" },
        { text: "ภ.5", group: "ภ.5" },
        { text: "ภ.6", group: "ภ.6" },
        { text: "ภ.7", group: "ภ.7" },
        { text: "ภ.8", group: "ภ.8" },
        { text: "ภ.9", group: "ภ.9" },
      ],
      items3: [],
      items4: [],

      count: 0,
      search: "",
      sangkat: "ภาพรวม ทั่วประเทศ",
      participantsQuestion: {
        คำถาม: "question",
        ตอบ1: "answer1",
        ตอบ2: "answer2",
        ตอบ3: "answer3",
        ตอบ4: "answer4",
        ตอบ5: "answer5",
      },
      json_fields: {
        ลำดับ: "count",
        ข้อเสนอแนะ: "answer",
      },
      // list: [
      //   {
      //     count: this.count,
      //   },
      // ],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      loading: false,
      showdata: false,
      showdataTable: false,
      headers: [
        { text: "ลำดับ", value: "count", align: "center", width: "20%" },
        // { text: "สถานที่", value: "station", align: "center", width: "20%" },
        { text: "ข้อเสนอแนะ", value: "answer", align: "left" },
        // { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      list: [],
      list2: [],
      showdata2: false,
      showdataTable2: false,
      checkCircle: "",
      questionSummary: "",
      questionSummaryItems: [
        { text: "เลือกแบบประเมิน", value: "" },
        { text: "แบบประเมินสำหรับผู้ฝึกอบรม", value: "questionSummary1" },
        {
          text: "แบบประเมินสำหรับ วิทยากร/ผู้บริหารจัดการโครงการ",
          value: "questionSummary2",
        },
        {
          text: "แบบประเมินสภาพชุมชน (สำหรับประชาชนในพื้นที่)",
          value: "customForm",
        },
        {
          text: "แบบประเมินสภาพชุมชน (สำหรับชุดปฏิบัติการที่ลงปฏิบัติในพื้นที่)",
          value: "surveyPhase1",
        },
        {
          text: "แบบประเมินความพึงพอใจ (สำหรับประชาชนในพื้นที่)",
          value: "surveyPhase2",
        },
        {
          text: "แบบประเมินความพึงพอใจ (สำหรับชุดปฏิบัติการที่ลงปฏิบัติในพื้นที่)",
          value: "surveyPhase2Officer",
        },
      ],
      questionSummary1: [],
      questionSummary2: [],
      getAllHistoryData1: [],
      getAllHistoryData2: [],
      customForm: [],
      customFormheaders: [
        { text: "ลำดับ", value: "count" },
        { text: "สถานี", value: "station" },
        { text: "จำนวนผู้ร่วมตอบแบบสอบถาม", value: "participants" },
        {
          text: "จำนวนผู้ Scan Qrcode แล้วตอบแบบสอบถาม",
          value: "scanQrCodeDo",
        },
        {
          text: "จำนวนผู้ Scan Qrcode แล้วไม่ตอบแบบสอบถาม",
          value: "scanQrCodeNot",
        },
        // {
        //   text: "จำนวนผู้แจ้งข่าวสาร",
        //   value: "participantsReport",
        // },
        {
          text: "จำนวนผู้แสดงความคิดเห็น",
          value: "participantsComment",
        },
        // {
        //   text: "วันที่บันทึก",
        //   value: "createdAt",
        // },
      ],
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
      clan: "",
      items: [
        { searchText: "ทั่วประเทศ", station: "" },
        { searchText: "ตำรวจภูธรภาค 1 ", station: "ภาค1" },
        { searchText: "ตำรวจภูธรภาค 2 ", station: "ภาค2" },
        { searchText: "ตำรวจภูธรภาค 3 ", station: "ภาค3" },
        { searchText: "ตำรวจภูธรภาค 4 ", station: "ภาค4" },
        { searchText: "ตำรวจภูธรภาค 5 ", station: "ภาค5" },
        { searchText: "ตำรวจภูธรภาค 6 ", station: "ภาค6" },
        { searchText: "ตำรวจภูธรภาค 7 ", station: "ภาค7" },
        { searchText: "ตำรวจภูธรภาค 8 ", station: "ภาค8" },
        { searchText: "ตำรวจภูธรภาค 9 ", station: "ภาค9" },
        { searchText: "ตำรวจนครบาล ", station: "ตำรวจนครบาล" },
      ],
      customFormMenu: "",
      customFormMenuitems: [
        { searchText: "ทั่วประเทศ", station: "ทั่วประเทศ" },
        { searchText: "ภาค", station: "ภาค" },
        { searchText: "จังหวัด", station: "จังหวัด" },
        { searchText: "สถานี", station: "สถานี" },
      ],
      pak: "",
      pakitems: [
        { text: "ทั่วประเทศ", group: "" },
        { text: "นครบาล", group: "น." },
        { text: "ภ.1", group: "ภ.1" },
        { text: "ภ.2", group: "ภ.2" },
        { text: "ภ.3", group: "ภ.3" },
        { text: "ภ.4", group: "ภ.4" },
        { text: "ภ.5", group: "ภ.5" },
        { text: "ภ.6", group: "ภ.6" },
        { text: "ภ.7", group: "ภ.7" },
        { text: "ภ.8", group: "ภ.8" },
        { text: "ภ.9", group: "ภ.9" },
      ],
      province: "",
      provinceitems: [],
      station: "",
      stationitems: [],
      exportCustomFormExcel: [],
      exportRawPeople: [],
      exportRawStaff: [],
      // ทั่วประเทศ
      // ภาค1-9
      // นครบาล
      // จังหวัด
      // สถานี
      series: 0,
      series2: 0,
      isShow: false,
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "50%",
            },
          },
          radialBar: {
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px",
              },
              value: {
                formatter: function (val) {
                  return ((val / 100) * 5).toFixed(1);
                },
                color: "#111",
                fontSize: "36px",
                show: true,
              },
            },
          },
        },
        labels: ["คะแนนเฉลี่ย"],
        fill: {
          colors: [
            function ({ value }) {
              if (value < 55) {
                return "#FF0F0F";
              } else if (value >= 55 && value < 80) {
                return "#FFB130";
              } else {
                return "#13FC61";
              }
            },
          ],
        },
      },
      chartOptionsSex: {
        chart: {
          width: "60%",
          type: "pie",
        },
        labels: ["หญิง", "ชาย", "ไม่ระบุ"],
        theme: {
          mode: "light",
          palette: "palette6",
          monochrome: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5,
            },
          },
        },
        title: {
          // text: "เพศ",
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            // console.log(val)
            return [name, val.toFixed(1) + "%"];
          },
        },
        legend: {
          show: false,
        },
      },
      chartOptionsAge: {
        chart: {
          width: "60%",
          type: "pie",
        },
        labels: [
          "21 - 30 ปี",
          "31 - 40 ปี",
          "41 - 50 ปี",
          "51 - 60 ปี",
          "61 ปีขึ้นไป",
        ],
        theme: {
          mode: "light",
          palette: "palette6",
          monochrome: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5,
              style: {
                fontSize: "14px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: "bold",
                colors: "#000",
              },
            },
          },
        },
        title: {
          // text: "ช่วงอายุ",
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + "%"];
          },
        },
        legend: {
          show: false,
        },
      },
      chartOptionsQ1: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            // return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
            // console.log(w.report);
            return (
              val +
              " คน (" +
              w.config.series[seriesIndex].data[dataPointIndex].z.toFixed(2) +
              " %)"
            );
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        // tooltip: {
        //   custom: function({series, seriesIndex, dataPointIndex, w}) {
        //     return '<div class="arrow_box">' +
        //       '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
        //       '</div>'
        //   }
        // }
      },
      seriesSex: [],
      seriesAge: [],
      seriesQ1: [],
      seriesQ2: [],
      seriesQ3: [],
      seriesQ4: [],
      seriesQ5: [],
      seriesQ6: [],
      seriesQ7: [],
      seriesQ8: [],
      seriesQ9: [],
      seriesQ10: [],
      seriesQ11: [],
      seriesQ12: [],
      seriesQ13: [],
      seriesQ14: [],
      seriesQ15: [],
      seriesQ16: [],
      seriesQ17: [],
      seriesQ18: [],
      seriesQ19: [],
      seriesQ20: [],
      report: [],
      reportOfficer: [],
      avgReport: 0,
      avgReportOfficer: 0,
      reportComment: [],
      reportOfficerComment: [],
      reportPhase1: [],
      avgReportPhase1: 0,
      reportCommentPhase1: [],
    };
  },
  async created() {
    // this.loading = true;
    // console.log("this.loading", this.loading);
    this.datefrom = new Date("2021-04-02").toISOString().substr(0, 10);
    //  await this.getReport();
    // this.getAllHistory();
    // this.searchDropdown();
  },
  methods: {
    async onChangeArea() {
      this.items3 = [];
      this.items4 = [];
      this.data.province = "";
      this.data.station = "";
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?group=` + this.data.area
      );
      this.items3 = response.data.data;
      this.loading = false;
      // this.items4 = response.data.data;
    },
    async onChangeProvince() {
      this.items4 = [];
      this.data.station = "";
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?province=` +
          this.data.province
      );
      this.items4 = response.data.data;
      this.loading = false;
      // this.items4 = response.data.data;
    },
    async searchDropdown() {
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      let aaa = response.data.data;
      console.log("search", response.data.data);

      // this.items2 = Array.from(
      //   new Set(this.items.map((a) => a.group.trim()))
      // ).map((group) => {
      //   return this.items.find((a) => a.group.trim() === group.trim());
      // });
      this.provinceitems = Array.from(
        new Set(aaa.map((a) => a.province.trim()))
      ).map((province) => {
        return aaa.find((a) => a.province.trim() === province.trim());
      });
      this.loading = false;
      console.log("provinceitems", this.provinceitems);
    },
    async getStation(province) {
      this.loading = true;
      const responseStation = await this.axios.get(
        `${process.env.VUE_APP_API}/stations/getByProvince` + province
      );
      this.loading = false;
      this.stationitems = responseStation.data.data;
      console.log("stationitems", responseStation.data.data);
      // stations/getByProvince/xxx
    },
    clear() {
      this.checkCircle = "";
      this.clan = "";
      this.questionSummary = "";
      this.datefrom = new Date("2021-04-02").toISOString().substr(0, 10);
      this.dateto = new Date().toISOString().substr(0, 10);
      this.customFormMenu = "";
      this.province = "";
      this.station = "";
      this.pak = "";
      this.data.area = "";
      this.data.station = "";
      this.data.province = "";
      // this.getReport();
    },
    // async searchDropdown() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/getAllStation"
    //   );
    //   this.items = response.data.data;
    //   console.log("search", response.data.data);
    //   this.items.forEach((item) => {
    //     item.searchText = item.station + " " + item.village;
    //   });
    // },
    async filter() {
      //       {
      //     "station":"ภาค3",
      //     "startDate":"2021-04-02",
      //     "endDate":"2021-04-03"
      // }
      const data = {
        station: this.clan,
        startDate: this.datefrom,
        endDate: this.dateto,
      };
      this.loading = true;
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/form/getReportWithFilter`,
        data
      );
      console.log("search", data);
      console.log("filter", response.data.data);
      this.questionSummary1 = response.data.data[0];
      this.series = this.questionSummary1.summaryFormAverage.toFixed(1);

      this.questionSummary2 = response.data.data[1];
      this.series2 = this.questionSummary2.summaryFormAverage.toFixed(1);
      this.checkCircle = this.questionSummary;
      console.log("checkCircle", this.checkCircle);
      this.loading = false;
    },
    showItem() {
      this.showdata = !this.showdata;
    },
    showTable() {
      this.showdataTable = !this.showdataTable;
    },
    showItem2() {
      this.showdata2 = !this.showdata2;
    },
    showTable2() {
      this.showdataTable2 = !this.showdataTable2;
    },
    valueToPercent(value) {
      const max = 5;
      return (value * 100) / max;
    },
    format(percent) {
      return percent / 20 + "/5";
    },
    async getAllHistory() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllHistory`
      );
      // this.getAllHistoryData1 = response.data.data[0];
      // this.getAllHistoryData2 = response.data.data[1];
      // console.log('getAllHistoryData',response.data.data);
    },
    async getReport() {
      this.exportRawPeople = [];
      this.exportRawStaff = [];
      this.seriesQ1 = [];
      this.seriesQ2 = [];
      this.seriesQ3 = [];
      this.seriesQ4 = [];
      this.seriesQ5 = [];
      this.seriesQ6 = [];
      this.seriesQ7 = [];
      this.seriesQ8 = [];
      this.seriesQ9 = [];
      this.seriesQ10 = [];
      this.seriesQ11 = [];
      this.seriesQ12 = [];
      this.seriesQ13 = [];
      this.seriesQ14 = [];
      this.seriesQ15 = [];
      this.seriesQ16 = [];
      this.seriesQ17 = [];
      this.seriesQ18 = [];
      this.seriesSex = [];
      this.seriesAge = [];

      this.sangkat = "ทั่วประเทศ";
      this.loading = true;
      this.checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
      if (this.checklogin.userType == "1" || this.checklogin.userType == "99") {
        this.opendiv199 = true;
      } else if (this.checklogin.userType == "2") {
        this.data.area = this.checklogin.area || "";
        // this.data = {
        //   area: this.checklogin.area || "",
        //   province: "",
        //   station: "",
        // };
        // this.onChangeArea();
        this.opendiv2 = true;
      } else if (this.checklogin.userType == "3") {
        this.data.area = this.checklogin.area || "";
        this.data.province = this.checklogin.province || "";
        // this.data = {
        //   area: this.checklogin.area || "",
        //   province: this.checklogin.province || "",
        //   station: "",
        // };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response.data.data;
        // this.onChangeProvince();

        console.log(this.data);
        this.opendiv3 = true;
      } else if (this.checklogin.userType == "4") {
        this.data = {
          area: this.checklogin.area,
          province: this.checklogin.province,
          station: this.checklogin.station,
        };

        const responseprovince = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?province=` +
            this.data.province
        );
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response.data.data;
        this.items4 = responseprovince.data.data;
        // this.checklogin.province
        // this.checklogin.area
        // this.data.station = this.checklogin.station;
        console.log("this.data", this.data);
        this.opendiv4 = true;
      }
      this.isShow = true;
      ////////////////////////////////////////////////////////////////////////////////////////////////////////
      // this.loading = true;
      if (
        this.questionSummary == "questionSummary1" ||
        this.questionSummary == "questionSummary2"
      ) {
        
        if (this.clan !== "") {
          this.sangkat = "ภาพรวม ตำรวจภูธร" + this.clan;
        }
        const data = {
          station: this.clan,
          startDate: this.datefrom,
          endDate: this.dateto,
        };
        this.loading = true;
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/form/getReportWithFilter`,
          data
        );
        // const response = await this.axios.get(
        //   `${process.env.VUE_APP_API}/form/getReport`
        // );

        console.log("data", response.data.data);
        let answer1 = response.data.data[0].answerText[0];
        this.exportRawStaff = response.data.data[1].participantsQuestion;
        this.exportRawPeople = response.data.data[0].participantsQuestion;
        this.list = answer1.filter((element) => {
          if (element.answer !== null && element.answer != "") {
            return element;
          }
        });

        // this.list = response.data.data[0].answerText[0];
        // this.list = response.data.data[0].answerText[0];
        console.log("list", this.list);
        for (let i in this.list) {
          this.list[i].count = parseInt(i) + 1;
        }
        this.checkCircle = this.questionSummary;
        // console.log("count", this.list);
        this.questionSummary1 = response.data.data[0];
        // console.log(">>>>>>>", this.questionSummary1.participants.choice);
        this.series = this.questionSummary1.summaryFormAverage.toFixed(1);
        //// ฟอร์ม 2
        let answer2 = response.data.data[1].answerText[0];
        this.list2 = answer2.filter((element) => {
          if (element.answer !== null && element.answer != "") {
            return element;
          }
        });
        // this.list2 = response.data.data[0].answerText[0];
        console.log("list2", this.list2);
        for (let i in this.list2) {
          this.list2[i].count = parseInt(i) + 1;
        }

        this.questionSummary2 = response.data.data[1];
        this.series2 = this.questionSummary2.summaryFormAverage.toFixed(1);
        this.loading = false;
      } else if (this.questionSummary == "customForm") {
        this.customForm = [];
        this.exportCustomFormExcel = [];
        if (this.customFormMenu == "ทั่วประเทศ") {
          this.pak = "";
        }
        console.log("เมนู", this.customFormMenu);
        const data = {
          group: this.data.area,
          province: this.data.province,
          station: this.data.station,
          startDate: this.datefrom,
          endDate: this.dateto,
        };
        console.log("data ที่ส่งไป", data);
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/assessCommunity/getSummaryReport` +
            `?group=` +
            this.data.area +
            "&province=" +
            this.data.province +
            "&station=" +
            this.data.station +
            "&fromDate=" +
            this.datefrom +
            "&toDate=" +
            this.dateto
        );
        console.log("api", response.data.inforPercent);

        if (response.data.inforPercent.countSex[0][0]) {
          this.seriesSex.push(response.data.inforPercent.countSex[0][0].amt);
        }
        if (response.data.inforPercent.countSex[0][1]) {
          this.seriesSex.push(response.data.inforPercent.countSex[0][1].amt);
        }
        if (response.data.inforPercent.countSex[0][2]) {
          this.seriesSex.push(response.data.inforPercent.countSex[0][2].amt);
        }

        this.seriesAge = [0, 0, 0, 0, 0];

        // if (age.topic === "20 - 29 ปี") {
        this.seriesAge[0] = response.data.inforPercent.allAge["1-20 ปี"];

        this.seriesAge[1] = response.data.inforPercent.allAge["21-30 ปี"];
        // }

        // if (age.topic === "30 - 39 ปี") {
        this.seriesAge[2] = response.data.inforPercent.allAge["31-40 ปี"];
        // }

        // if (age.topic === "40 - 49 ปี") {
        this.seriesAge[3] = response.data.inforPercent.allAge["41-50 ปี"];
        // }

        // if (age.topic === "70 ปีขึ้นไป") {
        this.seriesAge[4] = response.data.inforPercent.allAge["61 ปีขึ้นไป"];

        this.checkCircle = 9999;
        this.loading = false;
        this.pak = "";
        this.province = "";
        this.station = "";

        let data1 = {
          data: [],
          percent: [],
        };
        let data2 = {
          data: [],
        };
        let data3 = {
          data: [],
        };
        let data4 = {
          data: [],
        };
        let data5 = {
          data: [],
        };
        let data6 = {
          data: [],
        };
        let data7 = {
          data: [],
        };
        let data8 = {
          data: [],
        };
        let data9 = {
          data: [],
        };
        let data10 = {
          data: [],
        };
        let data11 = {
          data: [],
        };
        let data12 = {
          data: [],
        };
        let data13 = {
          data: [],
        };
        let data14 = {
          data: [],
        };
        let data15 = {
          data: [],
        };
        let data16 = {
          data: [],
        };
        let data17 = {
          data: [],
        };
        let data18 = {
          data: [],
        };
        let data19 = {
          data: [],
        };
        let data20 = {
          data: [],
        };
        let q1X = 0;
        // let q1Percent = 0
        response.data.inforPercent.educationGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data1.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ1.push(data1);

        response.data.inforPercent.currentHousingGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data2.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ2.push(data2);

        response.data.inforPercent.liveTimeGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data3.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ3.push(data3);

        response.data.inforPercent.careerGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data4.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ4.push(data4);

        response.data.inforPercent.happyGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data5.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ5.push(data5);

        response.data.inforPercent.drugSellGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data6.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ6.push(data6);

        response.data.inforPercent.takenDrugsGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data7.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ7.push(data7);

        response.data.inforPercent.contributorGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data8.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ8.push(data8);

        response.data.inforPercent.possessWeaponGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data9.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ9.push(data9);

        response.data.inforPercent.gamblingGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data10.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ10.push(data10);

        response.data.inforPercent.cutWoodGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data11.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ11.push(data11);

        response.data.inforPercent.destroyNaturalGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data12.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ12.push(data12);

        response.data.inforPercent.informalLoanGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data13.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ13.push(data13);

        response.data.inforPercent.influencerGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data14.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ14.push(data14);

        response.data.inforPercent.noisyDrivingGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data15.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ15.push(data15);

        response.data.inforPercent.stealingGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data16.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ16.push(data16);

        response.data.inforPercent.mingleGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data17.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ17.push(data17);

        response.data.inforPercent.drugEpidemicGroup.forEach((age) => {
          // q1Percent = (age.ansAmt / (age.total / 3)) * 100
          data18.data.push({
            x: age.topic,
            y: age.amt,
            z: (age.amt / age.total) * 100,
          });
        });
        this.seriesQ18.push(data18);

        // response.data.inforPercent.careerGroup.forEach((age) => {
        //   // q1Percent = (age.ansAmt / (age.total / 3)) * 100
        //   data4.data.push({
        //     x: age.topic,
        //     y: age.amt,
        //     z: (age.amt / age.total) * 100,
        //   });
        // });
        // this.seriesQ4.push(data4);
      } else if (this.questionSummary == "surveyPhase2") {
        this.report = [];
        if (this.customFormMenu == "ทั่วประเทศ") {
          this.pak = "";
        }
        console.log("เมนู", this.customFormMenu);
        const data = {
          group: this.data.area,
          province: this.data.province,
          station: this.data.station,
          startDate: this.datefrom,
          endDate: this.dateto,
        };
        console.log("data ที่ส่งไป", data);
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/summarySurveyPhase2` +
            `?area=` +
            this.data.area +
            "&province=" +
            this.data.province +
            "&station=" +
            this.data.station +
            "&fromDate=" +
            this.datefrom +
            "&toDate=" +
            this.dateto
        );
        console.log("api phase 2", response.data.data);
        this.report = response.data.data;
        this.reportComment = response.data.comment;

        this.avgReport =
          (this.report[0].ansAvg +
            this.report[1].ansAvg +
            this.report[2].ansAvg +
            this.report[3].ansAvg) /
          4;

        this.loading = false;
      } else if (this.questionSummary == "surveyPhase2Officer") {
        this.reportOfficer = [];
        if (this.customFormMenu == "ทั่วประเทศ") {
          this.pak = "";
        }
        console.log("เมนู", this.customFormMenu);
        const data = {
          group: this.data.area,
          province: this.data.province,
          station: this.data.station,
          startDate: this.datefrom,
          endDate: this.dateto,
        };
        console.log("data ที่ส่งไป", data);
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/summarySurveyPhase2Officer` +
            `?area=` +
            this.data.area +
            "&province=" +
            this.data.province +
            "&station=" +
            this.data.station +
            "&fromDate=" +
            this.datefrom +
            "&toDate=" +
            this.dateto
        );
        console.log("api phase 2", response.data.data);
        this.reportOfficer = response.data.data;
        this.reportOfficerComment = response.data.comment;

        this.avgReportOfficer =
          (this.reportOfficer[0].ansAvg +
            this.reportOfficer[1].ansAvg +
            this.reportOfficer[2].ansAvg +
            this.reportOfficer[3].ansAvg +
            this.reportOfficer[4].ansAvg) /
          5;

        this.loading = false;
      } else if (this.questionSummary == "surveyPhase1") {
        this.reportPhase1 = [];
        if (this.customFormMenu == "ทั่วประเทศ") {
          this.pak = "";
        }
        console.log("เมนู", this.customFormMenu);
        const data = {
          group: this.data.area,
          province: this.data.province,
          station: this.data.station,
          startDate: this.datefrom,
          endDate: this.dateto,
        };
        console.log("data ที่ส่งไป", data);
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/assessCommunity/summarySurveyPhase1Officer` +
            `?area=` +
            this.data.area +
            "&province=" +
            this.data.province +
            "&station=" +
            this.data.station +
            "&fromDate=" +
            this.datefrom +
            "&toDate=" +
            this.dateto
        );
        console.log("api phase 2", response.data.data);
        this.reportPhase1 = response.data.data;
        this.reportCommentPhase1 = response.data.comment;

        this.avgReportPhase1 =
          (this.reportPhase1[0].ansAvg +
            this.reportPhase1[1].ansAvg +
            this.reportPhase1[2].ansAvg +
            this.reportPhase1[3].ansAvg +
            this.reportPhase1[4].ansAvg) /
          5;

        this.loading = false;
      }
    },
    format(percent) {
      return percent / 20 + "/5 คะแนน";
    },
    async changeDropDown() {
      this.isShow = false;
      this.checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
      if (this.checklogin.userType == "1" || this.checklogin.userType == "99") {
        this.opendiv199 = true;
      } else if (this.checklogin.userType == "2") {
        this.data.area = this.checklogin.area || "";
        // this.data = {
        //   area: this.checklogin.area || "",
        //   province: "",
        //   station: "",
        // };
        this.onChangeArea();
        this.opendiv2 = true;
      } else if (this.checklogin.userType == "3") {
        this.data.area = this.checklogin.area || "";
        this.data.province = this.checklogin.province || "";
        // this.data = {
        //   area: this.checklogin.area || "",
        //   province: this.checklogin.province || "",
        //   station: "",
        // };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response.data.data;
        this.onChangeProvince();

        console.log(this.data);
        this.opendiv3 = true;
      } else if (this.checklogin.userType == "4") {
        this.data = {
          area: this.checklogin.area,
          province: this.checklogin.province,
          station: this.checklogin.station,
        };

        const responseprovince = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?province=` +
            this.data.province
        );
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response.data.data;
        this.items4 = responseprovince.data.data;
        // this.checklogin.province
        // this.checklogin.area
        // this.data.station = this.checklogin.station;
        console.log("this.data", this.data);
        this.opendiv4 = true;
      }
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
