<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4 mt-10"
      ><download-excel
        class="btn btn-default"
        :data="itemExportData"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="เอกสารสรุปข้อมูลการประเมินสภาพชุมชน.xls"
        :header="'ข้อมูลการประเมินสภาพชุมชน'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        ข้อมูลการประเมินสภาพชุมชน
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
import moment from "moment";

export default {
  props: ["itemExportData"],
  data() {
    return {
      json_fields: {
        // ลำดับ: "count",
        // ข้อเสนอแนะ: "answer",
        ชื่อชุมชน: "villageName",
        วันที่บันทึก: "createdAt",
        เพศ: "gender",
        อายุ: "age",
        ระดับการศึกษา: "education",
        ลักษณะที่พักอาศัยในปัจจุบัน: "currentHousing",
        "ท่านอาศัยอยู่ในชุมชน/หมู่บ้านนี้มาเป็นเวลาเท่าใด": "liveTime",
        ปัจจุบันท่านประกอบอาชีพอะไร: "career",
        "ท่านอาศัยอยู่ในชุมชน/หมูบ้านนี้มีความสุขมากน้อยเพียงใด": "happyLevel",
        มีบุคคลการขายยาเสพติด: "drugSales",
        มีบุคคลการเสพยาเสพติด: "takenDrugs",
        "มีบุคคลที่เกี่ยวข้องกับยาเสพติด เช่น ผู้สมคบ ผู้สนับสนุน ผู้ให้ความช่วยเหลือ":
          "contributor",
        "มีบุคคลครองครอง อาวุธปืนเถือน/วัตถุระเบิด/อาวุธสงคราม":
          "possessWeapon",
        "มีบุคคลที่มีพฤติกรรมชอบเล่นการพนัน/ขายหวยออนใต้ดิน": "gambling",
        "มีบุคคลที่มีพฤติกรรมลักลอบตัดไม้หวงข้าม/ไม่มีค่า": "cutWood",
        "มีบุคคลที่มีพฤติกรรมบุกรุกที่สาธารณะ/ทำลายทรัพยากรธรรมชาติ":
          "destroyNatural",
        มีบุคคลที่มีพฤติกรรมลักลอบจำหน่ายสินค้าหนีภาษี: "smuggledGoods",
        มีบุคคลที่มีพฤติกรรมออกเงินกู้นอกระบบ: "informalLoan",
        มีบุคคลที่มีพฤติกรรมเป็นผู้มีอิทธิพล: "influencer",
        "มีบุคคลที่มีพฤติกรรมการขับรถแว้น คึกคะนอง เสียงดัง": "noisyDriving",
        มีบุคคลที่มีพฤติกรรมลักเล็กขโมยน้อย: "stealing",
        "มีบุคคลที่มีพฤติกรรมจับกลุ่มดื่มสุรามั่วสุม/ชอบนำพวกทะเลาะวิวาท":
          "mingle",
        "ปัญหาอื่น ๆ ": "otherProblems",
        ท่านคิดว่าสถานการณ์การแแพร่ระบาดยาเสพติดในชุมชนท่านอยู่ระดับใด:
          "drugEpidemic",
        ท่านคิดว่าปัญหายาเสพติดจะลดลงหรือหมดไปจากชุมชนอย่างยั่งยืนได้อย่างไร:
          "reducedDrugProblem",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.moment();
  },
  methods: {
    // moment() {
    //   let answer1 = this.itemExportData;
    //   this.list1 = answer1.filter((element) => {
    //     if (element) {
    //       return moment(String(element)).format("MM/DD/YYYY hh:mm");
    //     }
    //   });
    //   console.log("list1", this.list1);
    // },
  },
};
</script>
