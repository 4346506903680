<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4 mt-10"
      ><download-excel
        class="btn btn-default"
        :data="itemProduct"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="เอกสารสรุปสรุปการประเมินชุมชน.xls"
        :header="'สรุปการประเมินชุมชน'"
        ><img src="@/assets/Excel-Logo.png" width="50px" />
        สรุปการประเมินชุมชน
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["itemProduct"],
  data() {
    return {
      json_fields: {
        // ลำดับ: "count",
        // ข้อเสนอแนะ: "answer",
        สถานี: "station",
        จำนวนผู้ร่วมตอบแบบสอบถาม: "participants",
        "จำนวนผู้ Scan Qrcode แล้วตอบแบบสอบถาม": "scanQrCodeDo",
        "จำนวนผู้ Scan Qrcode แล้วไม่ตอบแบบสอบถาม": "scanQrCodeNot",
        // จำนวนผู้แจ้งข่าวสาร: "participantsReport",
        จำนวนผู้แสดงความคิดเห็น: "participantsComment",

        
      },
      list2: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  async created() {
    // const response = await this.axios.get(
    //   `${process.env.VUE_APP_API}/assessCommunity/getAll`
    // );
    // console.log("response", response.data.data);
    // this.list2 = response.data.data;
  },
  methods: {},
};
</script>