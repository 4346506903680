<template>
  <v-card class="ma-6 pt-5">
    <v-row>
      <v-col cols="7" class="mt-6"
        ><apexchart
          type="pie"
          height="350"
          ref="chart"
          :options="chartOptionsPie"
          :series="seriesPie"
        ></apexchart
      ></v-col>
      <v-col cols="5">
        <v-row
          ><v-col cols="12"><h3>รายการประเมิน</h3></v-col>
        </v-row>
        <v-row>
          <v-col cols="8" align="center"><strong>หัวข้อ</strong></v-col>
          <v-col cols="3" align="right" class="mr-4"
            ><strong>ค่าเป้าหมาย</strong></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="8" class="ml-4"
            >1. ร้อยละของชุมที่มีประสิทธิภาพการดำเนินงานในระดับดี
            <v-chip>1000</v-chip></v-col
          >
          <v-col cols="3" align="right"
            ><v-chip color="blue">ระดับดี</v-chip></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="8" align="start" class="ml-4"
            >2.
            ร้อยละผลการประเมินความพึงพอใจผู้เกี่ยวข้องที่ร่วมโครงการแต่ละชุมชน<v-chip
              >7500</v-chip
            ></v-col
          >
          <v-col cols="3" align="right"
            ><v-chip color="green">ระดับดี</v-chip></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="8" align="start" class="ml-4"
            >3.
            ร้อยละจำนวนผู้เสพยาเพิ่มขึ้นมีอัตราที่ลตลงในชุมชนที่ร่วมโครงการ<v-chip
              >6800</v-chip
            ></v-col
          >
          <v-col cols="3" align="right"
            ><v-chip color="orange">ร้อยละ 7.3</v-chip></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="8" align="start" class="ml-4"
            >4.
            ร้อยละจำนวนชุมชนที่ร่วมโครงการมีผลการตำเนินงานหลังส่งมอบพื้นที่อย่าง
            ต่อเนื่อง<v-chip>6280</v-chip></v-col
          >
          <v-col cols="3" align="right"
            ><v-chip color="red">ร้อยละ 24.3</v-chip></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="8" align="start" class="ml-4"
            >5.
            ร้อยละจำนวนชุมชนที่ร่วมโครงการได้รับรางวัลชมชนยั่งยืนระดับประเทศ<v-chip
              >4212</v-chip
            ></v-col
          >
          <v-col cols="3" align="right"
            ><v-chip color="purple">ร้อยละ 12.4</v-chip></v-col
          >
        </v-row>
        <!-- <v-row>
            <v-col cols="8" align="start" class="ml-4"
              >6.
              ร้อยละจำนวนสถานนีตำรวจที่ร่วมโครงการได้รับรางวัลผลปฏิบัติงานดีเยี่ยม
              ระดับบประเทศ</v-col
            >
            <v-col cols="3" align="right"><v-chip color="orange">ร้อยละ 80</v-chip></v-col>
          </v-row> -->
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      seriesPie: [44, 55, 13, 43, 22],
      chartOptionsPie: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["blue", "green", "orange", "red", "purple"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
};
</script>