<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4"
      ><download-excel
        class="btn btn-default"
        :data="itemProduct"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="แบบประเมินการฝึกอบรมชุดปฏิบัติการระดับภาค สำหรับ วิทยากร/ผู้บริหารจัดการโครงการ.xls"
        :header="'แบบประเมินการฝึกอบรมชุดปฏิบัติการระดับภาค สำหรับ วิทยากร/ผู้บริหารจัดการโครงการ วันที่ ' + formDate + ' - ' + toDate"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        Download ข้อมูลการฝึกอบรม สำหรับ วิทยากร/ผู้บริหารจัดการโครงการ
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["itemProduct", "formDate", "toDate"],
  data() {
    return {
      json_fields: {
        "คำถาม": "question",
        "ให้ 1 คะแนน": "answer1",
        "ให้ 2 คะแนน": "answer2",
        "ให้ 3 คะแนน": "answer3",
        "ให้ 4 คะแนน": "answer4",
        "ให้ 5 คะแนน": "answer5",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  },
};
</script>
